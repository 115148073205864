import React from "react";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import Hero from "../sections/camelo/before-after/Hero";
import PeopleAreTalking from "../sections/camelo/before-after/PeopleAreTalking";
import Fact from "../sections/camelo/Fact";
import BottomCTA from "../sections/camelo/BottomCTA";
import SuccessStories from "../sections/camelo/SuccessStories";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const BeforeAfterPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Before & After switching to Camelo"
          description="Discover amazing and practical changes businesses gain before andafter they switch to Camelo."
        ></SEO>
        <Hero></Hero>
        <PeopleAreTalking />
        <Fact darkMode={false} />
        <MiddleCTA></MiddleCTA>
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default BeforeAfterPage;
